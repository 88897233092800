@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700&display=swap");

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Regular.ttf") format("truetype"),
    url("./assets/fonts/Gilroy-Regular.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Bold.ttf") format("truetype"),
    url("./assets/fonts/Gilroy-Bold.ttf") format("opentype");
  font-weight: 700;
  font-style: bold;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Medium.ttf") format("truetype"),
    url("./assets/fonts/Gilroy-Medium.ttf") format("opentype");
  font-weight: 500;
  font-style: medium;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/Gilroy-SemiBold.ttf") format("opentype");
  font-weight: 600;
  font-style: bold;
}

#root {
  font-family: font-Gilroy, sans-serif;
}

.input-box-shadow {
  filter: drop-shadow(-4px -4px 44px rgba(0, 0, 0, 0.08));
}

.slick-prev:before,
.slick-next:before {
  color: #5247ff;
  font-size: 40px;
}

.slick-next:before {
  position: absolute;
  right: 0;
  margin-top: -10px;
  margin-right: 5px;
}

.slick-next,
.slick-prev {
  position: absolute;
  right: 0;
}

.slick-prev {
  z-index: 99999;
  margin-left: 25px;
}

.category-card:hover .arrow-icon {
  fill: white;
}

.slick-arrow {
  position: absolute;
  right: 50;
  margin-right: -6px;
}

.slick-dots {
  @apply ml-[-10px] hidden;
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .comp1,
  .comp2,
  .comp5,
  .comp6,
  .comp7,
  .comp8,
  .comp9,
  .comp10,
  .comp11,
  .comp12 {
    padding: 30px;
  }
  .introimage {
    top: 6%;
    left: 29%;
    width: 43%;
    height: 87%;
  }
  .banimg {
    width: 100%;
  }
  .feaimg {
    height: 20vh;
  }
  .limg {
    width: 70%;
  }
  .tabheight {
    max-height: 380px;
  }
}
